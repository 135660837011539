import React from "react";
import styled from "styled-components";

export const TwoColumnGrid = ({
  style,
  children,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}): JSX.Element => (
  <TwoColGridStyles
    style={{
      ...style,
    }}
  >
    {children}
  </TwoColGridStyles>
);
const TwoColGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
  @media (max-width: ${(props) => props.theme.screenSize.m - 100}px) {
    grid-template-columns: 1fr;
  }
`;
// For future ref in case want to change back later.. TwoColGrid previous 'wrapping grid' styles as follows:
// display: grid;
// grid-template-columns: repeat(auto-fit, minmax(min-content, 500px));
// grid-column-gap: 4rem;
// grid-row-gap: 2rem;

export const FourColumnGrid = ({
  style,
  children,
  twoColumnMinimum,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  twoColumnMinimum?: boolean;
}): JSX.Element => (
  <FourColGridStyles
    style={{
      ...style,
    }}
    twoColumnMinimum={twoColumnMinimum}
  >
    {children}
  </FourColGridStyles>
);
const FourColGridStyles = styled.div<{ twoColumnMinimum?: boolean }>`
  // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4rem;
  @media (max-width: ${(props) => props.theme.screenSize.xl}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${(props) => props.theme.screenSize.m - 100}px) {
    grid-template-columns: ${(props) =>
      props.twoColumnMinimum ? "repeat(2, 1fr)" : "1fr"};
  }
`;
