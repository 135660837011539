import React from "react";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import {
  BlogPostByCategory,
  ProjectByCategory,
  ProjectCategoryName,
} from "../utils/types";
import { LinkInternal } from "./LinkInternal";

export const BlogPostCategoryCard = ({
  style,
  to,
  categoryName,
  blogPostsCount,
  isCategorySelected,
}: {
  style?: React.CSSProperties;
  to?: string;
  categoryName: ProjectCategoryName;
  blogPostsCount?: number;
  isCategorySelected?: boolean;
}): JSX.Element => {
  return (
    <CatergoryCard
      key={uuid()}
      style={style}
      isCategorySelected={isCategorySelected}
      aria-label={`Blog post category filter ${categoryName}. There ${
        blogPostsCount === 1 ? "is" : "are"
      } ${blogPostsCount} ${
        blogPostsCount === 1 ? "blog post" : "blog posts"
      } in the ${categoryName} category`}
    >
      {to && blogPostsCount ? (
        <LinkInternal to={to}>
          {`${categoryName} · 
          ${blogPostsCount}`}
        </LinkInternal>
      ) : (
        <span>{categoryName}</span>
      )}
    </CatergoryCard>
  );
};

export const BlogPostCategoryCards = ({
  style,
  pathname,
  blogPostsTotalCount,
  blogPostsByCategory,
}: {
  style?: React.CSSProperties;
  pathname: LocationState;
  blogPostsTotalCount: number;
  blogPostsByCategory: BlogPostByCategory[];
}): JSX.Element => {
  const isBlogHome = pathname === "/blog";

  return (
    <CatergoriesContainer style={style}>
      <h4
        tabIndex={0}
        aria-label="You can use the following links to filter blog posts by category"
      >
        Category filter&#58;
      </h4>
      {blogPostsByCategory.length && (
        <CatergoriesList>
          <BlogPostCategoryCard
            to={`/blog`}
            categoryName={"All"}
            blogPostsCount={blogPostsTotalCount}
            isCategorySelected={isBlogHome}
          />
          {blogPostsByCategory.map((category: BlogPostByCategory) => {
            const categoryLowerCase = category.blog_post_category_name.toLowerCase();
            const isCategorySelected =
              pathname === `/blog/${categoryLowerCase}`;
            return (
              <BlogPostCategoryCard
                key={uuid()}
                to={`/blog/${categoryLowerCase}`}
                categoryName={category.blog_post_category_name}
                blogPostsCount={category.blogPostCount}
                isCategorySelected={isCategorySelected}
              />
            );
          })}
        </CatergoriesList>
      )}
    </CatergoriesContainer>
  );
};

export const ProjectCategoryCard = ({
  style,
  to,
  categoryName,
  projectsCount,
  isCategorySelected,
}: {
  style?: React.CSSProperties;
  to?: string;
  categoryName: ProjectCategoryName;
  projectsCount: number;
  isCategorySelected?: boolean;
}): JSX.Element => {
  return (
    <CatergoryCard
      key={uuid()}
      style={style}
      isCategorySelected={isCategorySelected}
      aria-label={`Projects category filter ${categoryName}. There ${
        projectsCount === 1 ? "is" : "are"
      } ${projectsCount} ${
        projectsCount === 1 ? "project" : "projects"
      } in the ${categoryName} category`}
    >
      {to ? (
        <LinkInternal to={to}>
          {`${categoryName} · 
          ${projectsCount}`}
        </LinkInternal>
      ) : (
        <span>{categoryName}</span>
      )}
    </CatergoryCard>
  );
};

export const ProjectCategoryCards = ({
  style,
  pathname,
  projectsTotalCount,
  projectsByCategory,
}: {
  style?: React.CSSProperties;
  pathname: LocationState;
  projectsTotalCount: number;
  projectsByCategory: ProjectByCategory[];
}): JSX.Element => {
  const isProjectHome = pathname === "/projects";

  return (
    <CatergoriesContainer style={style}>
      <h4
        tabIndex={0}
        aria-label="You can use the following links to filter projects by category"
      >
        Category filter&#58;
      </h4>
      {projectsByCategory.length && (
        <CatergoriesList>
          <ProjectCategoryCard
            to={`/projects`}
            categoryName={"All"}
            projectsCount={projectsTotalCount}
            isCategorySelected={isProjectHome}
          />
          {projectsByCategory.map((category: ProjectByCategory) => {
            const categoryNameLowerCase = category.project_category_name.toLowerCase();
            const isCategorySelected =
              pathname === `/projects/${categoryNameLowerCase}`;
            return (
              <ProjectCategoryCard
                key={uuid()}
                to={`/projects/${categoryNameLowerCase}`}
                categoryName={category.project_category_name}
                projectsCount={category.projectsCount}
                isCategorySelected={isCategorySelected}
              />
            );
          })}
        </CatergoriesList>
      )}
    </CatergoriesContainer>
  );
};

const CatergoriesContainer = styled.div`
  display: flex;
`;
const CatergoriesList = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  li {
    list-style-type: none;
    margin: 0 0 1rem 2rem;
    &:before {
      content: none;
    }
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      margin-left: 0 0 1rem 1rem;
    }
  }
`;
const CatergoryCard = styled.li<{ isCategorySelected?: boolean }>`
  a,
  span {
    color: ${(props) => props.theme.colours.background};
    background: ${(props) =>
      props.theme.colours[props.isCategorySelected ? "midBlue" : "lightGrey"]};
    padding: 5px 10px;
    border-radius: ${(props) => props.theme.borderRadius.button};
    &:hover,
    &:focus-visible {
      color: ${(props) => props.theme.colours.background};
      background: ${(props) => props.theme.colours.midBlue};
    }
  }
`;
