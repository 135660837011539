import React from "react";
import { Main } from "./MainStyles";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const PageOuter = ({
  style,
  children,
  title,
  midLayout,
  wideLayout,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  title: string;
  midLayout?: boolean;
  wideLayout?: boolean;
}): JSX.Element => {
  return (
    <Main id="main" tabIndex={-1} style={style}>
      <title>{title}</title>
      <MaxWidthContainer midLayout={midLayout} wideLayout={wideLayout}>
        {children}
      </MaxWidthContainer>
    </Main>
  );
};
