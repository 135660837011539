import React, { useCallback } from "react";
import Image from "gatsby-background-image";
import styled from "styled-components";
import { Link } from "gatsby";
import { v4 as uuid } from "uuid";
// import { useWindowDimensions } from "../utils/useWindowDimensions";
import { convertStringToSlug } from "../utils/functions";
import { Project } from "../utils/types";
import { TwoColumnGrid } from "../components/GridLayouts";
import Spacer from "../components/Spacer";
import { ProjectCategoryList } from "../components/ProjectCategoryList";

export const ProjectCard = ({
  style,
  project,
  to,
}: {
  style?: React.CSSProperties;
  project: Project;
  to?: string;
}): JSX.Element => {
  const { title, title_image, project_categories } = project;

  const onPreventClick = useCallback(
    (event: React.FormEvent<HTMLAnchorElement>) => {
      event.preventDefault();
    },
    []
  );

  return (
    <>
      <ProjectCardLink
        style={{
          ...style,
          ...(!to && { pointerEvents: "none" }),
        }}
        to={to ?? "/"}
        onClick={to ? undefined : onPreventClick}
        aria-label={`Link to the ${project.title} project`}
      >
        <ImageStyled
          style={{ objectPosition: "center" }}
          fluid={title_image?.childImageSharp?.fluid || null}
        />
        <TextContainer>
          <h3>{title}</h3>
          <Spacer />
          {project_categories && (
            <ProjectCategoryList projectCategories={project_categories} />
          )}
        </TextContainer>
      </ProjectCardLink>
    </>
  );
};

const ProjectCardLink = styled(Link)`
  background: ${(props) => props.theme.colours.background};
  border-radius: ${(props) => props.theme.borderRadius.card};
  div {
    transition: box-shadow 0.2s ease-in;
  }
  &:hover, &:focus-visible {
    h3 {
      transition: color 0.2s ease-in;
      color ${(props) => props.theme.colours.midBlue};
    }
    > div:first-of-type {
      cursor: pointer;
      box-shadow: 0 0 10px ${(props) => props.theme.colours.shadow};
    }
  }
`;
const ImageStyled = styled(Image)`
  // width: 100%;
  // aspect-ratio: 1.3/1; // Safari did not support this
  padding: 77% 1rem 2rem 1rem; /* padding top 1.3:1 Aspect Ratio (1/1.3 = 0.77) */
  border-radius: ${(props) => props.theme.borderRadius.card};
  &:before,
  &:after {
    border-radius: ${(props) => props.theme.borderRadius.card};
    background-position-y: center !important; // override gatsby-image default of bottom to keep fluid image centered when changing screen size and/or image dimensions
  }
`;
const TextContainer = styled.div`
  padding: 2rem 1rem;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.card};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.card};
  p {
    margin: 0;
  }
`;

export const ProjectCards = ({
  style,
  projects,
}: {
  style?: React.CSSProperties;
  projects: Project[];
}): JSX.Element => {
  return (
    <TwoColumnGrid>
      {projects.map(
        (
          project: Project
          // , index: number
        ) => {
          // const isInColumnTwo = index % 2;

          const projectSlug = convertStringToSlug(project.title);

          return (
            <ProjectCard
              key={uuid()}
              project={project}
              style={style}
              to={
                project.background_section !== "-"
                  ? `/projects/${projectSlug}`
                  : undefined
              }
            />
          );
        }
      )}
    </TwoColumnGrid>
  );
};
