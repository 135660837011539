import React from "react";
import "aos/dist/aos.css";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { ProjectCategory } from "../utils/types";

export const ProjectCategoryList = ({
  style,
  projectCategories,
  tabIndex,
}: {
  style?: React.CSSProperties;
  projectCategories: ProjectCategory[];
  tabIndex?: number;
}): JSX.Element => {
  return (
    <CategoriesList style={style} tabIndex={tabIndex}>
      {projectCategories.map((category, index) => {
        const lastItem = index === projectCategories.length - 1;
        return (
          <li key={uuid()}>
            {`${category.project_category_name}`}
            {!lastItem && <span>·</span>}
          </li>
        );
      })}
    </CategoriesList>
  );
};
const CategoriesList = styled.ul`
padding-left: 0; // undo default list styles from Layout first
display: flex;
flex-direction: row;
flex-wrap: wrap;
li {
  margin-bottom: 0; // undo default list styles from Layout first
  margin-right: 0.5rem;
  span {
    margin-left: 0.5rem;
  }
  &:before {
    content: none; // undo default list styles from Layout first
  }
}

}
`;
