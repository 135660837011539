import React from "react";
import styled from "styled-components";

const PageIntro = ({
  style,
  title,
  intro,
  noTitleFade,
  noIntroFade,
}: {
  style?: React.CSSProperties;
  title: string;
  intro: string;
  noTitleFade?: boolean;
  noIntroFade?: boolean;
}): JSX.Element => {
  return (
    <PageIntroStyles
      style={{
        ...style,
      }}
      tabIndex={0}
    >
      <h2 data-aos={noTitleFade ? "" : "fade"}>{title}</h2>
      <p
        data-aos={noIntroFade ? "" : "fade"}
        data-aos-delay="250"
        data-aos-disable={noIntroFade}
      >
        {intro}
      </p>
    </PageIntroStyles>
  );
};
const PageIntroStyles = styled.div`
  margin-bottom: 6rem;
`;

export default PageIntro;
