import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Img from "gatsby-image";
import styled from "styled-components";
// import PlaceholderLogoImg from "../assets/images/placeholder-logo.png";
import MedicBleepLogo from "../assets/images/medic-bleep-logo.svg";
import { FourColumnGrid } from "./GridLayouts";
import { FluidImage, SilentAny } from "../utils/types";
import Spacer from "./Spacer";

export const OurPartnersLogosSection = ({
  data,
}: {
  data: SilentAny;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);
  return (
    <>
      <div tabIndex={0}>
        <h2 data-aos="fade">Our Partners</h2>
        <Subtext
          data-aos="fade-right"
          data-aos-duration={750}
          data-aos-easing="ease-out-quad"
        >
          We are proud to have worked with some amazing clients, including&#58;
        </Subtext>
      </div>
      <Spacer />
      <FourColumnGrid twoColumnMinimum>
        <ImagesContainer>
          <ImageStyled fluid={data.threeLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled fluid={data.zishiLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled fluid={data.zenziumLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled fluid={data.sisuLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled
            fluid={data.myHappyMindLogo.childImageSharp.fluid}
            alt=""
          />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled fluid={data.porscheLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled
            fluid={data.carterCorsonLogo.childImageSharp.fluid}
            alt=""
          />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled
            fluid={data.sanFranZooLogo.childImageSharp.fluid}
            alt=""
          />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled fluid={data.smartyLogo.childImageSharp.fluid} alt="" />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled
            fluid={data.storeRoomLogo.childImageSharp.fluid}
            alt=""
          />
        </ImagesContainer>
        <ImagesContainer>
          <ImageStyled
            fluid={data.toyotaConnectedLogo.childImageSharp.fluid}
            alt=""
          />
        </ImagesContainer>
        <ImagesContainer>
          <SvgStyled src={MedicBleepLogo} alt="" />
        </ImagesContainer>
      </FourColumnGrid>
    </>
  );
};

const ImagesContainer = styled.section`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colours.whiteLogoBackground};
  border-radius: ${(props) => props.theme.borderRadius.card};
  padding: 10px;
`;
// passing fluid prop to styled component here to stop tsc warning
const ImageStyled = styled(Img)<{ fluid: FluidImage }>`
  margin: auto;
  width: 180px;
  height: 180px;
  max-width: 180px;
  max-height: 180px;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    max-width: 140px;
    max-height: 140px;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    max-width: 100px;
    max-height: 100px;
  }
`;
const SvgStyled = styled.img`
  width: 180px;
  height: 180px;
  max-width: 180px;
  max-height: 180px;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    max-width: 140px;
    max-height: 140px;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    max-width: 100px;
    max-height: 100px;
  }
`;
const Subtext = styled.h4`
  color: ${(props) => props.theme.colours.foregroundSecondary};
  font-weight: normal;
  max-width: 40%;
  margin: 2rem 0;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    max-width: 60%;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    max-width: 100%;
  }
`;
